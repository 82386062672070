import { Injectable, Injector } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { BaseService } from './base.service';
import { IntegrationType, RoleActions } from '@shared/utils/constants';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  logOut() {
    super.logOut();
  }

  storeToken(token: string) {
    this.storageService.set('token', token);
  }

  storeIntegrationURLCallback(integrationURLCallback: string) {
    this.storageService.set('integrationURLCallback', integrationURLCallback);
  }

  storeIntegrationCode(integrationCode: string) {
    this.storageService.set('integrationCode', integrationCode);
  }

  getIntegrationCode(): string {
    return this.storageService.get('integrationCode');
  }

  getIntegrationURLCallback(): string {
    return this.storageService.get('integrationURLCallback');
  }

  isAdminUser(): boolean {
    return this.userHasAction(RoleActions.USER_ADMIN);
  }

  canAccessConfiguration(): boolean {
    return this.userHasAction(RoleActions.CONFIGURATION_ACCESS);
  }

  canReopenAConversation() {
    return this.userHasAction(RoleActions.REOPEN_FINALIZED_CONVERSATION);
  }

  private decryptToken(): any {
    const token = this.token;
    return token ? jwtDecode(token) : null;
  }

  userHasAction(action: RoleActions) {
    return this.actions.includes(action);
  }

  get actions(): any[] {
    const decrypted = this.decryptToken();
    return JSON.parse(decrypted?.actions) || [];
  }

  get loggedCompanyName(): string {
    const decrypted = this.decryptToken();
    return decrypted?.loggedCompanyName || 'Corporativo';
  }

  get loggedUserId(): number {
    const tokenData = this.getTokenData();
    return parseInt(tokenData.id, 10);
  }

  getTokenData(): any {
    return this.decryptToken();
  }

  extractTokenData(token: string): any {
    return jwtDecode(token);
  }

  get loggedCompanyId(): number {
    const tokenData: any = this.getTokenData();
    return parseInt(tokenData.loggedCompanyId, 10);
  }

  get isWhatsappOfficial() {
    const tokenData: any = this.getTokenData();

    if (!tokenData) {
      return false;
    }

    return tokenData.integrationType
      && !isNaN(parseInt(tokenData.integrationType, 10))
      && parseInt(tokenData.integrationType, 10) === IntegrationType.WHATSAPP_OFFICIAL;
  }
}
